import React, { Component } from 'react'
import ButtonComponent from '../ButtonComponent/ButtonComponent'


class Addon extends Component {


  // Tab
  openTabSection = (evt, tabNmae) => {
    let i, tabcontent, tablinks;
    tabcontent = document.getElementsByClassName("tabs_item");
    for (i = 0; i < tabcontent.length; i++) {
      tabcontent[i].classList.remove("fadeIn");
      tabcontent[i].style.display = "none";
    }

    tablinks = document.getElementsByTagName("li");
    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace("current", "");
    }

    document.getElementById(tabNmae).style.display = "block";
    document.getElementById(tabNmae).className += " fadeIn animated";
    evt.currentTarget.className += "current";
  }

  render() {
    return (
      <section className="trading bgremove pt-100 mb-0">
        <div className="container">
          <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">

            <h3 className="heading-h2"><span className="heading-h3"><span className="bluecolor">Add on Modules</span> of our</span>
              SushiSwap clone Script Software</h3>
          </div>
          <div className="row">
            <div className="courses-details-desc">
              <ul className="nav nav-tabs tabNav nav-pills" id="myTab" role="presentation">
                <li role='presentation' onKeyDown={this.openTabSection} className="current" onClick={(e) => this.openTabSection(e, 'tabs1')}>
                  Multilingual support
                </li>
                <li role='presentation' onKeyDown={this.openTabSection} onClick={(e) => this.openTabSection(e, 'tabs2')} >
                  Analytics
                </li>
                <li role='presentation' onKeyDown={this.openTabSection} onClick={(e) => this.openTabSection(e, 'tabs3')} >
                  API Documentation
                </li>
                <li role='presentation' onKeyDown={this.openTabSection} onClick={(e) => this.openTabSection(e, 'tabs4')} >
                  Dark Mode
                </li>
                <li role='presentation' onKeyDown={this.openTabSection} onClick={(e) => this.openTabSection(e, 'tabs5')} >
                  Completely decentralized
                </li>

              </ul>
              <div className="tab-content">
                <div id="tabs1" className="tab-pane tabs_item">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <h4 className="heading-h4">Multilingual Support
                      </h4>
                      <p className="pharagraph">Our SushiSwap clone script comes with multi-lingual support which makes it more effective for global competition and you can attract several crypto users from various parts of the world.
                      </p>
                      <div className="text-left mt-4">
                        <ButtonComponent />
                      </div>
                    </div>
                    <div className="col-md-6 text-center order1" >
                      <img width="394px" height="333px" src="https://coinsclone.mo.cloudinary.net/images/sushiswap-new/multilingual-support.png" alt="Multilingual support" />
                    </div>
                  </div>
                </div>
                <div id="tabs2" className="tab-pane tabs_item">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <h4 className="heading-h4">Analytics
                      </h4>
                      <p className="pharagraph">Users can view the top liquidity pairs and check the liquidity provided for that pair, volume, fees, trades and also all these details graphically.
                      </p>
                      <div className="text-left mt-4"> <ButtonComponent /> </div>
                    </div>
                    <div className="col-md-6 text-center order1" >
                      <img width="309px" height="274px" src="https://coinsclone.mo.cloudinary.net/images/sushiswap-new/analytics.png" alt="Analytics" />
                    </div>
                  </div>
                </div>
                <div id="tabs3" className="tab-pane tabs_item">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <h4 className="heading-h4">API Documentation
                      </h4>
                      <p className="pharagraph">Our SushiSwap clone script API is created for programmers who want to connect the documentation to their exchanges such as live market data, Liquidity, trade volumes and so on.
                      </p>
                      <div className="text-left mt-4"> <ButtonComponent /> </div>
                    </div>
                    <div className="col-md-6 text-center order1" >
                      <img width="450px" height="261px" src="https://coinsclone.mo.cloudinary.net/images/sushiswap-new/api-documentaton.png" alt="API Documentation" />
                    </div>
                  </div>
                </div>
                <div id="tabs4" className="tab-pane tabs_item">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <h4 className="heading-h4">Dark Mode</h4>
                      <p className="pharagraph">Give your users a dark mode experience while they trade to keep the exchange more impressive and user friendly.
                      </p>
                      <div className="text-left mt-4"> <ButtonComponent /> </div>
                    </div>
                    <div className="col-md-6 text-center order1" >
                      <img width="529px" height="292px" src="https://coinsclone.mo.cloudinary.net/images/sushiswap-new/dark-mode.png" alt="Dark Mode" />
                    </div>
                  </div>
                </div>
                <div id="tabs5" className="tab-pane tabs_item">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <h4 className="heading-h4">Completely decentralized
                      </h4>
                      <p className="pharagraph">Rejection of the regular trade system such as Signup, Sign in and ID verification makes our SushiSwap clone script stay unnamed and thus saves your time by just associating the wallets and swapping the tokens.
                      </p>
                      <div className="text-left mt-4"> <ButtonComponent /> </div>
                    </div>
                    <div className="col-md-6 text-center order1" >
                      <img width="270px" height="263px" src="https://coinsclone.mo.cloudinary.net/images/sushiswap-new/completely-decentralized.png" alt="Completely decentralized " />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Addon