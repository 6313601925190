import React from 'react'



class Whatis extends React.Component {


  render() {

    return (
      <section className="whatis mb-0 py-0">
        <div className='gray-bg'>
      <div className="container">
        <div className="shadoww">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
              <h2 className="heading-h2"><span className="heading-h3 bluecolor">What is </span>
              White Label SushiSwap clone Script?</h2>
            </div>
          </div>

          <div className="text-center">
            <p className="pharagraph" >SushiSwap clone script is the fully decentralized and pre-designed DeFi exchange clone software. This clone script helps you to launch an elite DeFi exchange 100% similar to SushiSwap within 7 days. Coinsclone offers the premium SushiSwap clone script that exactly mirrors the core capabilities of the existing platform - SushiSwap. It is the most trending DeFi exchange clone software that has been designed for swapping ERC20 tokens for the most ardent crypto enthusiasts. 
            </p>
            <p className="pharagraph mb-0">Our exclusive SushiSwap clone script is built on the powerful Ethereum blockchain. This clone script allows your users to join the decentralized network and generate unlimited active & passive income. Besides, this script comprises all the robust features and security modules to operate a DeFi exchange for the long run. It also provides great incentives to liquidity providers. By using our SushiSwap clone software, you can also customize your smart contracts and other key functionalities based on your business necessities. The clone script we offer has been developed by blockchain developers who have been setting new benchmarks in the industry.
            </p>
          </div>
        </div>
      </div>
      </div>
    </section>
    )
  }
}

export default Whatis