import React from 'react'



const DevApproach = () => {


  return (
    <section className="devapproach pt-100 mb-5">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
            
            <h3 className="heading-h2"><span className="heading-h3"><span className="bluecolor">Development Approach</span> of</span>
            Our SushiSwap clone Script </h3>
            <p className="pharagraph head">We at Coinsclone undergo the following procedures for SushiSwap clone script development.
            </p>
          </div>
        </div>
        <div className="row table-content orderflex">
          <div className="col-lg-6 col-md-12  text-left order1" >
            <img width="376px" height="278px" src="https://coinsclone.mo.cloudinary.net/images/sushiswap-new/requirment-gathering.png" alt="Development approach" />
          </div>
          <div className="col-lg-6 col-md-12 padl60 order2">
            <h5 >Requirement Gathering</h5>
            <p className="pharagraph" >The more we understand your requirement, the better Sushiswap-powered solution we can provide.
            </p>
            <br />
            <h5 >Planning </h5>
            <p className="pharagraph" >We present to you a wireframe of our solution where you can see how data and logic flow from one screen to another.
            </p>
          </div>
        </div>
        <div className="row table-content orderflex">
          <div className="col-lg-6 col-md-12 padr40 order2">
            <h5 >Designing</h5>
            <p className="pharagraph" >All your users will be interacting with your web and mobile application through the user interface, which we will develop with extreme care.</p>
            <br />
            <h5 >Development</h5>
            <p  className="pharagraph">Being a white label script, there is ample scope for customization and development in the SushiSwap clone script, which we can gladly carry out for you.
            </p>
          </div>
          <div className="col-lg-6 col-md-12  text-right order1">
            <img width="314px" height="269px" src="https://coinsclone.mo.cloudinary.net/images/sushiswap-new/Designing.png" alt="Designing" /></div>
        </div>
        <div className="row table-content orderflex mb-0">
          <div className="col-lg-6 col-md-12  text-left order1" >
            <img width="374px" height="285px" src="https://coinsclone.mo.cloudinary.net/images/sushiswap-new/testing.png" alt="Testing and Deployment" />
          </div>
          <div className="col-lg-6 col-md-12 padl60 order2">
            <h5 >Testing</h5>
            <p  className="pharagraph">Rigorous testing ensures that once the script is installed it is ready for public use and the rigors of crypto transactions.
            </p>
            <br />
            <h5 >Deployment</h5>
            <p  className="pharagraph mb-0">Once your application is tested it will be deployed upon the appropriate platform.
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default DevApproach