import React from 'react'
import ButtonComponent from '../ButtonComponent/ButtonComponent'

class WhyShould extends React.Component {

  render() {

    return (
      <section className="whyshould pt-100 mb-0">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">

              <h2 className="heading-h2"><span className="heading-h3"><span className='bluecolor'>Why should</span> you Create a</span>
                DeFi Exchange using ready-to-launch SushiSwap clone Script?
              </h2>
              <p className="pharagraph head">
              </p>
            </div>
          </div>
          <div className="row table-content">
            <div className="col-lg-6 col-md-12  text-center" >
              <img width="554px" height="538px" src="https://coinsclone.mo.cloudinary.net/images/sushiswap-new/why-should-you-create-a.png" alt="SushiSwap clone script software" />
            </div>
            <div className="col-lg-6 col-md-12">
              <ul>
                <li className="pharagraph" >It is a 100% customizable dashboard
                </li>
                <li className="pharagraph" >The premium SushiSwap clone script has a cutting-edge admin console and an eye-catching user dashboard.
                </li>
                <li className="pharagraph" >This script is crafted on top of the secure blockchain network - Ethereum.
                </li>
                <li className="pharagraph" >The white label coding in this clone script allows complete branding and personalization.
                </li>
                <li className="pharagraph" >Instant deployment. 
                </li>
                <li className="pharagraph" >Cost-effective DeFi exchange clone script.</li>
                <li className="pharagraph" >Generate high ROI in a short period with unique strategies.
                </li>
                <li className="pharagraph" >This script is developed by the finest software development team.
                </li>
                <li className="pharagraph" >Highly responsive on both mobile and website.
                </li>
                <li className="pharagraph" >Pre-loaded with all essential DeFi-based DEX features.
                </li>
              </ul>

              <div className="banner-btn mt-4">
                <ButtonComponent />
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default WhyShould