import React, { Component } from 'react'
import ButtonComponent from '../ButtonComponent/ButtonComponent'


class CoreFeatures extends Component {

  // Tab
  openTabSection = (evt, tabName) => {
    let i, tabcontent, tablinks;
    tabcontent = document.getElementsByClassName("tabs_items");
    for (i = 0; i < tabcontent.length; i++) {
      tabcontent[i].classList.remove("fadeIn");
      tabcontent[i].style.display = "none";
    }

    tablinks = document.getElementsByTagName("li");
    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace("current", "");
    }

    document.getElementById(tabName).style.display = "block";
    document.getElementById(tabName).className += " fadeIn animated";
    evt.currentTarget.className += "current";
  }

  render() {
    return (
      <section className="trading bgremove pt-100 mb-0">
 
        <div className="container">
          <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">

            <h3 className="heading-h2"><span className="heading-h3"><span className="bluecolor">Core Features</span> of </span>
              Our SushiSwap clone Script</h3>
          </div>
          <div className="row">

            <div className="courses-details-desc">
              <ul className="nav nav-tabs tabNav nav-pills" id="myTab" role='presentation'>
                <li className="current" role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab1')}>
                  Farms
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab2')} >
                  Liquidity migration
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab3')} >
                  Rebalancing of pool ratios
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab4')} >
                  Connect Wallet
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab5')} >
                  Lending
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab6')} >
                  Borrowing
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab7')} >
                  Staking
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab8')} >
                  Transaction Speed
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab9')} >
                  Community revenue sharing
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab10')} >
                  IFO 
                </li>
              </ul>

              <div className="tab-content">
                <div id="tab1" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h4 className="heading-h4">Farms
                        </h4>
                        <p className="pharagraph">Liquidity providers get Sushi LP tokens for providing liquidity and these LP tokens can be staked in respective farms to earn rewards.
                        </p>
                        <div className="text-left mt-4">
                          <ButtonComponent />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6  text-center order1" >
                      <img width="439px" height="253px" src="https://coinsclone.mo.cloudinary.net/images/sushiswap-new/farms.png" alt="Farms" />
                    </div>
                  </div>
                </div>
                <div id="tab2" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h4 className="heading-h4">Liquidity Migration
                        </h4>
                        <p className="pharagraph">Tokens staked for liquidity in this platform can be migrated to the Sushiswap contracts. This creates new liquidity pools for the token holders.
                        </p>
                        <div className="text-left mt-4"> <ButtonComponent />
                        </div> </div>
                    </div>
                    <div className="col-md-6  text-center order1" >
                      <img width="287px" height="369px" src="https://coinsclone.mo.cloudinary.net/images/sushiswap-new/liquidity-migration.png" alt="Liquidity migration" />
                    </div>
                  </div>
                </div>
                <div id="tab3" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h4 className="heading-h4">Rebalancing of Pool Ratios
                        </h4>
                        <p className="pharagraph">Liquidity pools are rebalanced in real-time by adjusting the price between them through smart contracts. This guarantees seamless liquidity.
                        </p>
                        <div className="text-left mt-4"> <ButtonComponent />
                        </div> </div>
                    </div>
                    <div className="col-md-6  text-center order1" >
                      <img width="478px" height="330px" src="https://coinsclone.mo.cloudinary.net/images/sushiswap/rebalancing-of-pool-ratios.png" alt="Rebalancing of pool ratios" />
                    </div>
                  </div>
                </div>
                <div id="tab4" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h4 className="heading-h4">Connect Wallet
                        </h4>
                        <p className="pharagraph">Easily connect external wallets like MetaMask, Binance wallet, trust wallet etc., and start trading in our SushiSwap clone script exchange. It is hassle-free and easy to use eliminating the need to create an account, unlike centralized exchanges.
                        </p>
                        <div className="text-left mt-4"> <ButtonComponent />
                        </div> </div>
                    </div>
                    <div className="col-md-6  text-center order1" >
                      <img width="232px" height="316px" src="https://coinsclone.mo.cloudinary.net/images/sushiswap-new/connect-wallet.png" alt="Connect Wallet" />
                    </div>
                  </div>
                </div>
                <div id="tab5" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h4 className="heading-h4">Lending
                        </h4>
                        <p className="pharagraph">Provide crypto for others to borrow and earn interest on it
                        </p>
                        <div className="text-left mt-4"> <ButtonComponent />
                        </div> </div>
                    </div>
                    <div className="col-md-6  text-center order1" >
                      <img className="mw380" width="414px" height="283px" src="https://coinsclone.mo.cloudinary.net/images/sushiswap-new/lending.png" alt="Lending" />
                    </div>
                  </div>
                </div>
                <div id="tab6" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h4 className="heading-h4">Borrowing
                        </h4>
                        <p className="pharagraph">Borrow crypto after providing collateral
                        </p>
                        <div className="text-left mt-4"> <ButtonComponent />
                        </div> </div>
                    </div>
                    <div className="col-md-6  text-center order1" >
                      <img width="370px" height="328px" src="https://coinsclone.mo.cloudinary.net/images/sushiswap-new/borrowing.png" alt="Borrowing" />
                    </div>
                  </div>
                </div>
                <div id="tab7" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h4 className="heading-h4">Staking
                        </h4>
                        <p className="pharagraph">Deposit your SushiSwap tokens to earn rewards</p>
                        <div className="text-left mt-4"> <ButtonComponent />
                        </div> </div>
                    </div>
                    <div className="col-md-6  text-center order1" >
                      <img className="mw315" width="495px" height="273px" src="https://coinsclone.mo.cloudinary.net/images/sushiswap-new/staking.png" alt="Staking" />
                    </div>
                  </div>
                </div>
                <div id="tab8" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h4 className="heading-h4">Transaction Speed
                        </h4>
                        <p className="pharagraph">Our SushiSwap clone script allows thousands of transactions per second, allowing multiple users to use the exchange simultaneously, without lag time.
                        </p>
                        <div className="text-left mt-4"> <ButtonComponent />
                        </div> </div>
                    </div>
                    <div className="col-md-6  text-center order1" >
                      <img width="440px" height="232px" src="https://coinsclone.mo.cloudinary.net/images/sushiswap-new/transaction-speed.png" alt="Transaction Speed" />
                    </div>
                  </div>
                </div>
                <div id="tab9" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h4 className="heading-h4">Community revenue sharing
                        </h4>
                        <p className="pharagraph">The trade commission is shared also with the liquidity providers along with the admin. So revenue is shared within the community to attract users and also motivate them to provide liquidity for all pairs.</p>
                        <div className="text-left mt-4"> <ButtonComponent />
                        </div> </div>
                    </div>
                    <div className="col-md-6  text-center order1" >
                      <img className="mw380" width="443px" height="301px" src="https://coinsclone.mo.cloudinary.net/images/sushiswap-new/community-revenue-sharing.png" alt="Community revenue sharing" />
                    </div>
                  </div>
                </div>
                <div id="tab10" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h4 className="heading-h4">IFO 
                        </h4>
                        <p className="pharagraph">Here users can buy newly launched tokens. Create a profile, stake your token in the IFO pool and earn IFO credits which can be used to secure your commitment limit in the next IFO sale and buy new tokens.</p>
                        <div className="text-left mt-4"> <ButtonComponent />
                        </div> </div>
                    </div>
                    <div className="col-md-6  text-center order1" >
                      <img width="353px" height="223px" src="https://coinsclone.mo.cloudinary.net/images/sushiswap-new/ifo.png" alt="IFO" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default CoreFeatures