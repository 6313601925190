import React from 'react'
import Slider from "react-slick";
import ButtonComponent from '../ButtonComponent/ButtonComponent'



class Banner extends React.Component {

  render() {

    const settings = {
      dots: false,
      infinite: true,
      speed: 2500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,   
      autoplaySpeed: 1000
    };

    return (
      <section className="banner">
        <div className="container">
          <div className="row table-content">
            <div className="col-lg-5 col-md-12 col-sm-12 col-12 left-banner">
              <div className="mw600">
                <h1 className="main-heading"><span className="bluecolor">SushiSwap</span> Clone Script</h1>
                <p className="sub-heading">Start your own Ethereum-based DeFi exchange business immediately with our first-class SushiSwap clone script.</p>
                <ul>
                  <li className="pharagraph">Ready-made DeFi Exchange clone.
                  </li>
                  <li className="pharagraph">Customizable, scalable, and expandable.
                  </li>
                  <li className="pharagraph">SushiSwap clone script comes with Out-of-the-box features.
                  </li>
                </ul>
                <div className="cta-banner">
                <ButtonComponent />
                <a href="https://calendly.com/mathi-pixelweb/" className="bluebtn">Book a calendly meeting</a>
                </div>
              </div>
            </div>
            <div className="col-lg-7 col-md-12 col-sm-12 col-12 right-banner">
            <div className="screen">
                <img width="806px" height="547px" src="https://coinsclone.mo.cloudinary.net/images/binance-new/laptop-binance.png" alt="SushiSwap clone laptop front view" />
                <div className="outder">
                  <Slider {...settings}>
                    <img width="647px" height="403px" src="https://coinsclone.mo.cloudinary.net/images/sushiswap-new/white-label-sushiswap-clone-script-software.png" alt="white label SushiSwap clone script software" />
                    <img width="647px" height="403px" src="https://coinsclone.mo.cloudinary.net/images/sushiswap-new/sushiswap-clone-script.png" alt="SushiSwap clone script" />
                    <img width="647px" height="403px" src="https://coinsclone.mo.cloudinary.net/images/sushiswap-new/sushiswap-clone.png" alt="sushiswap-clone" />
                  </Slider>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default Banner