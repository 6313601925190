import React from 'react'

import ButtonComponent from '../ButtonComponent/ButtonComponent'

class TopFeatures extends React.Component {

  render() {
    return (
      <section className="topfeaturez pt-100 mb-0">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
              <h2 className="heading-h2"><span className="heading-h3"><span className="bluecolor">Top Features</span> of </span>
                Our SushiSwap clone Script</h2>
            </div>
          </div>
          <div className="row table-content orderflex">
            <div className="col-md-12 col-lg-6 order2">
              <div className="top-left" >
                <h4 className="heading-h4">Swap</h4>
                <p className="pharagraph">Swap your tokens instantly at the best rates on the decentralized community built on Ethereum.
                </p>
                <div className="text-left mobile-hide">
                  <ButtonComponent />
                </div>
              </div>
            </div>
            <div className="col-md-12 col-lg-6 right-side order1">
              <img  width="536px" height="313px" src="https://coinsclone.mo.cloudinary.net/images/sushiswap-new/swap.png" alt="Swap" />
            </div>
          </div>
          <div className="row table-content orderflex">
            <div className="col-md-12 col-lg-6 order1">
              <img  width="536px" height="313px" src="https://coinsclone.mo.cloudinary.net/images/sushiswap-new/amm-automated-market-maker.png" alt="AMM - Automated Market Maker" />
            </div>
            <div className="col-md-12 col-lg-6 order2">
              <div className="top-right" >
                <h4 className="heading-h4">AMM - Automated Market Maker</h4>
                <p className="pharagraph">Our SushiSwap clone script eliminates the order matching engine and order books with AMM (Automatic Market Makers). Here users trade against liquidity locked in smart contracts known as liquidity pools.
                </p>
                <div className="text-left mobile-hide">
                  <ButtonComponent />
                </div>
              </div>
            </div>
          </div>
          <div className="row table-content orderflex">
            <div className="col-md-12 col-lg-6 order2">
              <div className="top-left" >
                <h4 className="heading-h4">Liquidity Pools</h4>
                <p className="pharagraph">Users/liquidity providers deliver liquidity to the pool and get incentives in the form of Sushi tokens. 0.25% trading fee goes to the liquidity providers and the remaining 0.05% is converted to Sushi which is distributed among the top liquidity holders.</p>
                <div className="text-left mobile-hide">
                  <ButtonComponent />
                </div>
              </div>
            </div>
            <div className="col-md-12 col-lg-6  order1">
              <img  width="551px" height="313px" src="https://coinsclone.mo.cloudinary.net/images/sushiswap-new/liquidity-pools.png" alt="Liquidity Pools" />
            </div>
          </div>
          <div className="row table-content orderflex">
            <div className="col-md-12 col-lg-6 right-side order1">
              <img  width="551px" height="313px" src="https://coinsclone.mo.cloudinary.net/images/sushiswap-new/security.png" alt="Security" />
            </div>
            <div className="col-md-12 col-lg-6 order2">
              <div className="top-right" >
                <h4 className="heading-h4">Security</h4>
                <p className="pharagraph">Our SushiSwap clone script is robust, fully tested, verified and will last forever without giving possibilities for unprotected security attacks.
                </p>
                <div className="text-left mobile-hide">
                  <ButtonComponent />
                </div>
              </div>
            </div>

          </div>
          <div className="row table-content orderflex mb-0">
            <div className="col-md-12 col-lg-6 order2">
              <div className="top-left" >
                <h4 className="heading-h4">Token distribution</h4>
                <p className="pharagraph">This is an inbuilt feature in the SushiSwap clone script where the earned tokens are effectively distributed among the stakers to keep their liquidity pools flowing.
                </p>
                <div className="text-left ">
                  <ButtonComponent />
                </div>
              </div>
            </div>
            <div className="col-md-12 col-lg-6  order1">
              <img  width="536px" height="313px" src="https://coinsclone.mo.cloudinary.net/images/sushiswap-new/token-distribution.png" alt="Token distribution" />
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default TopFeatures