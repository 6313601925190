import React, { useState } from "react";
import { Accordion, Container } from 'react-bootstrap'



const FaqSection = () => {


  const [activeId, setActiveId] = useState('');

  function toggleActive(id) {
    if (activeId === id) {
      setActiveId(null);
    } else {
      setActiveId(id);
    }
  }

  return (
    <section className="faq pt-100">

      <Container>
        <div className="text-center">
          
          <h4 className="heading-h2"><span className="heading-h3 bluecolor d-none d-sm-block">FAQ</span>
            Frequently Asked Questions</h4>
        </div>

        <Accordion defaultActiveKey={activeId}>
            <div className={activeId === '0' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">

                <Accordion.Toggle onClick={() => toggleActive('0')} className="panel-toggle" variant="link" eventKey="0">
                Does the SushiSwap clone script work exactly like the Sushiswap?
                </Accordion.Toggle>
                
              </div>

              <Accordion.Collapse eventKey="0">
                <div className="panel-body card-body">Yes, the SushiSwap clone script works exactly like the existing popular DeFi exchange - Sushiswap. In fact, our clone script works better than the sushiswap as it has some of the best additional features and functionalities which are not currently available in the SushiSwap.
                </div>
              </Accordion.Collapse>
            </div>
            <div className={activeId === '1' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('1')} className="panel-toggle" variant="link" eventKey="1">
                How much does it cost to build a DeFi exchange using the SushiSwap clone script?
                </Accordion.Toggle>
                
              </div>

              <Accordion.Collapse eventKey="1">
                <div className="panel-body card-body">We can’t predict the exact cost of SushiSwap clone exchange development as it involves more factors to consider. Such as features customization, add-on modules you integrate, and the blockchain you choose for operation. But it mainly depends on your business requirements and urgent needs. Talk to our experts and know the exact cost of the SushiSwap clone script.
                </div>
              </Accordion.Collapse>
            </div>
            <div className={activeId === '2' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('2')} className="panel-toggle" variant="link" eventKey="2">
                Can I Customize your SushiSwap clone software?
                </Accordion.Toggle>
                
              </div>

              <Accordion.Collapse eventKey="2">
                <div className="panel-body card-body">Our premium SushiSwap clone script is a white label software which means its code can be optimized in any manner you feel like. So, once you purchase the clone script from us, you can modify them as per your wish in a trouble-free manner.
                </div>
              </Accordion.Collapse>
            </div>
            <div className={activeId === '3' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('3')} className="panel-toggle" variant="link" eventKey="3">
                Why Should I get the SushiSwap clone script from Coinsclone?
                </Accordion.Toggle>
                
              </div>

              <Accordion.Collapse eventKey="3">
                <div className="panel-body card-body">The clone script that you acquire from Coinsclone is highly responsive, 100% customizable, bug-free, reliable, and built with peculiar features. Besides, we have a team of skilled blockchain developers, designers, and quality analysts. So, getting a script from Coinsclone will be the ultimate solution for your business. 
                </div>
              </Accordion.Collapse>
            </div>
            <div className={activeId === '4' ? 'panel-wrap active-panel card mb-0' : 'panel-wrap card mb-0'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('4')} className="panel-toggle" variant="link" eventKey="4">
                Will I get support after the project is delivered? 
                </Accordion.Toggle>
                
              </div>

              <Accordion.Collapse eventKey="4">
                <div className="panel-body card-body">Yes, you will get the finest 24/7 technical and customer support through the internet from our team of experts.
                </div>
              </Accordion.Collapse>
            </div>
        </Accordion>
      </Container>

    </section>
  )
}

export default FaqSection